<template>
  <!-- 
    画面: 宿リスト編集
    用途: 宿リストを更新または削除する
   -->
  <div>
    <!-- #region Main -->
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            {{ $t("facilityListEditPage.pageTitle") }}
          </CCardHeader>

          <CCardBody>
            <span class="caption">
              {{ $t("facilityListEditPage.caption") }}
            </span>
            <CForm class="submit_form">
              <!-- #region 入力フィールド -->

              <!-- #region 名称 -->
              <CRow>
                <CCol class="label"
                  ><strong class="required-mark">{{
                    $t("facilityListEditPage.name")
                  }}</strong></CCol
                >
                <CCol>
                  <CInput v-model="edit.name" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.name.$dirty && !$v.edit.name.required"
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("facilityListEditPage.name"),
                      })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.name.$dirty && !$v.edit.name.maxLength"
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("facilityListEditPage.name"),
                        vMax: $v.edit.name.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 名称 -->

              <!-- #region 説明 -->

              <CRow>
                <CCol class="label"
                  ><strong>{{
                    $t("facilityListEditPage.description")
                  }}</strong></CCol
                >
                <CCol>
                  <CTextarea class="mb-2" v-model="edit.description" />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.description.$dirty &&
                      !$v.edit.description.maxLength
                    "
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("facilityListEditPage.description"),
                        vMax: $v.edit.description.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>

              <!-- #endregion 説明 -->

              <!-- #region リスト -->
              <CRow>
                <CCol
                  ><div class="float-left">
                    <h4 class="sub-title">
                      {{ $t("facilityListEditPage.facilityList") }}
                    </h4>
                    <p>
                      <span>{{ $t("facilityListEditPage.totalNumber") }}</span
                      >: {{ facilityCount }}
                    </p>
                  </div>
                  <AddFacilityModal :callback="addFacility" :addeds="addeds" />
                </CCol>
              </CRow>
              <CRow class="mx-2">
                <CCol class="list-box">
                  <CDataTable
                    class="mb-0 table-outline"
                    hover
                    size="sm"
                    :items="editTableItems"
                    :fields="TableFields"
                    head-color="light"
                    table-filter
                    column-filter
                    sorter
                    itemps-per-page-select
                    :items-per-page="100"
                    pagination
                    v-if="!loading"
                    :tableFilter='{ placeholder: $t("tableFilter.placeholder"), label: $t("tableFilter.label") }'
                  >
                    <td slot="uniqueId" class="text-left" slot-scope="{ item }">
                      {{ item.uniqueId }}
                    </td>
                    <td slot="name" class="text-left" slot-scope="{ item }">
                      {{ item.name }}
                    </td>
                    <td
                      slot="pronunciation"
                      class="text-left"
                      slot-scope="{ item }"
                    >
                      {{ item.pronunciation }}
                    </td>
                    <td slot="address" slot-scope="{ item }" class="text-left">
                      {{ item.address1 + item.address2 }}
                    </td>
                    <td
                      slot="telephone"
                      slot-scope="{ item }"
                      class="text-left"
                    >
                      {{ item.telephone }}
                    </td>
                    <td slot="email" slot-scope="{ item }" class="text-left">
                      {{ item.email }}
                    </td>
                    <template #action="{ item }">
                      <td class="p-0 text-center">
                        <CButton
                          class="mt-1"
                          color="danger"
                          square
                          size="sm"
                          @click="onDeleteRowClicked(item.uniqueId)"
                        >
                          <strong>ー</strong></CButton
                        >
                      </td>
                    </template>
                  </CDataTable>
                </CCol>
              </CRow>
              <!-- #endregion 無効 -->

              <!-- #endregion 入力フィールド -->

              <!-- #region 更新ボタン -->
              <CButton
                class="px-5 mt-4 float-left"
                color="info"
                @click="onUpdateClicked()"
                v-bind:class="activeStatus"
              >
                <i class="icon cil-task"></i> {{ $t("common.update") }}
              </CButton>
              <!-- #endregion 更新ボタン -->

              <!-- #region 削除ボタン -->
              <CButton
                class="px-5 mt-4 float-right"
                color="danger"
                @click="onDeleteClicked()"
              >
                <i class="icon cil-trash"></i> {{ $t("common.delete") }}
              </CButton>
              <!-- #endregion 削除ボタン -->
            </CForm>
          </CCardBody>

          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton color="secondary" @click="onBackClicked()">
              <i class="icon cil-arrow-left mr-2"></i>
              {{ $t("common.back") }}</CButton
            >
            <!-- #endregion 戻るボタン -->
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Main -->

    <DeleteConfirmModal
      :deleteConfirmModal="deleteConfirmModal"
      :callback="reqDeleteFacilityList"
      @close="deleteConfirmModal = $event"
    />

    <SuccessModal :successModal="successModal" @close="successModal = $event" />

    <SuccessModal
      :successModal="deleteSuccessModal"
      @close="deleteSuccessModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
  </div>
</template>

<script>
import i18n from "@/i18n";
import SuccessModal from "@/components/SuccessModal";
import ErrorModal from "@/components/ErrorModal";
import DeleteConfirmModal from "@/components/DeleteConfirmModal";
import AddFacilityModal from "./components/AddFacilityModal";

import {
  required,
  // numeric,
  // minLength,
  maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  name: "FacilityListEdit",

  components: {
    SuccessModal,
    ErrorModal,
    DeleteConfirmModal,
    AddFacilityModal,
  },

  computed: {
    activeStatus() {
      console.log(this.$v);
      return this.$v.edit.$invalid ? "inactive" : "active";
    },
    facilityCount() {
      return this.editTableItems.length;
    },

    addeds() {
      return this.editTableItems.map((a) => a.uniqueId);
    },
  },
  watch: {
    deleteSuccessModal(newValue, oldValue) {
      // 削除後のSuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },

  data() {
    return {
      //#region Flag
      loading: false,
      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,
      deleteSuccessModal: false,
      addFacilityModal: false,

      //#endregion Flag

      //#region Message
      errorMessage: "error",
      //#endregion Message

      /** 宿検索結果 */
      facilities: [],
      //facilityCount: 0,

      //#region Input
      edit: {
        name: "",
        description: "",
        hotelLists: [],
      },

      //editTableItems: [],
      //開発用
      editTableItems: [
        {
          uniqueId: null,
          checkbox: null,
          name: "",
          pronunciation: "",
          address1: "",
          telephone: "",
          email: "",
        },
      ],

      TableFields: [
        {
          key: "uniqueId",
          label: "ID",
          _classes: "text-center bg-light",
          _style: "width:5%",
        },
        {
          key: "name",
          label: i18n.t("facilitySearchPage.facilitiesList.tableHeader.name"),
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
        },
        {
          key: "pronunciation",
          label: i18n.t(
            "facilitySearchPage.facilitiesList.tableHeader.pronunciation"
          ),
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
        },
        {
          key: "address",
          label: i18n.t(
            "facilitySearchPage.facilitiesList.tableHeader.address"
          ),
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
        },
        {
          key: "telephone",
          label: i18n.t(
            "facilitySearchPage.facilitiesList.tableHeader.telephone"
          ),
          sorter: true,
          filter: true,
          _style: "width:12%",
          _classes: "text-center bg-light",
        },
        {
          key: "email",
          label: i18n.t("facilitySearchPage.facilitiesList.tableHeader.email"),
          _classes: "text-center bg-light",
        },
        {
          key: "action",
          label: "",
          sorter: false,
          filter: false,
          _style: "width:8%",
          _classes: "text-center bg-light",
        },
      ],
      //#endregion Input

      //#region Response data

      response: [],

      //#endregion Response data
    };
  },

  validations() {
    return {
      edit: {
        name: {
          required,
          maxLength: maxLength(50),
        },
        description: {
          maxLength: maxLength(1000),
        },
      },
    };
  },

  methods: {
    //#region Event
    /** テーブル内の削除ボタン押下 */
    onDeleteRowClicked(uniqueId) {
      for (let i = 0; i < this.editTableItems.length; i++) {
        if (this.editTableItems[i].uniqueId == uniqueId) {
          this.editTableItems.splice(i, 1);
          console.log(this.editTableItems);
        }
      }
    },

    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    /** 削除ボタン押下 */
    onDeleteClicked() {
      this.deleteConfirmModal = true;
    },

    /** 削除ボタン押下 */
    onDeleteSubmit() {
      this.reqDeleteFacilityList();
    },

    /** 更新ボタン押下 */
    onUpdateClicked() {
      this.reqPutFacilityList();

      // this.successModal = true;
    },

    //#endregion Event

    //#region Request

    /** 宿リスト削除 */
    reqDeleteFacilityList() {

      const callback = () => {
        this.deleteConfirmModal = false;
        this.deleteSuccessModal = true;
      };

      const errorCallback = (err) => {
        this.deleteConfirmModal = false;
        this.errorMessage = err + "'  post to facility '";
        this.errorModal = true;
      };

      this.reqDeleteHotelList(callback,errorCallback);
    },


    /** 宿リスト更新 */
    reqPutFacilityList() {

      this.editTableItems.forEach((a) => {
        this.edit.hotelLists.push({hotelId: a.uniqueId,});
      });
      
      const body = this.edit;

      const callback = () => {
        this.successModal = true;
        this.reqGetFacilityList();
      };

      const errorCallback = (err) => {
        this.errorMessage = err + "'  post to facility '";
        this.errorModal = true;
      };

      this.reqPutHotelList(body,callback,errorCallback);
    },


    /** １つの宿リスト取得 */
    reqGetFacilityList() {
      const callback = (a) => {
        const datas = a;
        this.edit.name = datas.name;
        this.edit.description = datas.description;
        this.pourTable(datas.hotelLists);
        this.loading = false;
      };

      const errorCallback = (err) => {
        console.log(err);
        this.loading = false;
      };

      this.reqGetHotelList(callback,errorCallback);
    },


    //#endregion Request

    //#region Method
    /**
     * 一覧表にデータを流し込む
     * @param datas
     */
    pourTable(datas) {
      this.clearTable();
      datas.forEach((data) => {
        this.editTableItems.push({
          uniqueId: data.hotelId,
          name: data.name,
          pronunciation: data.pronunciation,
          address2: data.address2,
          address1: data.address1,
          telephone: data.telephone,
          email: data.email,
        });
      });
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.editTableItems = [];
    },

    /**  */
    createTableItems(tableItem) {
      const tableItems = new Array(tableItem);
      return tableItems;
    },
    addFacility(items) {
      for (let item of items.filter((a) => a.checkbox)) {
        const exists = this.editTableItems.find(
          (b) => b.uniqueId === item.uniqueId
        );
        if (exists) continue;
        this.editTableItems.push(item);
        console.log(this.editTableItems);
      }
    },
    backPage() {
      this.$router.go(-1);
    },
    //#endregion Method
  },
  beforeMount() {
    this.reqGetFacilityList();
  },
};
</script>
