<template>
  <!-- 
    画面: 有効無効選択リスト
    用途: リスト項目の有効無効を設定する
   -->
  <div>
    <CRow>
      <CCol>
        <!-- #region button -->
        <CButton
          class="px-5 mt-4 float-left"
          color="info"
          @click="onAddFacilityClicked()"
        >
          <i class="icon cil-plus">{{ $t("common.add") }}</i>
        </CButton>
        <!-- #endregion button -->
      </CCol>
    </CRow>
    <CModal :show.sync="addFacilityModal" size="xl" color="info">
      <template #header
        ><h4 class="modal-title">
          {{ $t("facilityListModal.pageTitle") }}
        </h4></template
      >
      <!-- #region 検索 -->

      <CRow
        ><CCol>
          <p>{{ $t("facilityListModal.description") }}</p>
          <CForm class="search-filter" onsubmit="return false;">
            <CRow class="mb-2">
              <CCol lg="5" class="search-btn">
                <!-- #region 検索条件選択 -->
                <label class="editor-title">{{
                  $t("facilityListModal.searchCriteria")
                }}</label>
                <CSelect
                  class="search-lbl"
                  :options="queryOptions"
                  :value.sync="selectedQuery"
                />
                <!-- #endregion 検索条件選択 -->

                <!-- #region すべて検索 -->
                <template v-if="isSelectedQuery('all')">
                  <CButton @click="onSearchClicked('all')" class="info-button">
                    <i class="icon cil-search"></i> {{ $t("common.search") }}
                  </CButton>
                </template>
                <!-- #endregion すべて検索 -->
              </CCol>

              <!-- #region 宿名検索 -->
              <template v-if="isSelectedQuery('facilityName')">
                <CCol sm="3" lg="3">
                  <CInput
                    class="search-input"
                    :placeholder="
                      $t('facilityListModal.facilityNamePlaceholderText')
                    "
                    v-model="query.facilityName"
                    type="text"
                    @keydown.enter="onEnterKeyPress($event, 'facilityName')"
                  />
                </CCol>
                <CCol sm="3" lg="3">
                  <CButton
                    @click="onSearchClicked('facilityName')"
                    class="info-button"
                    :disabled="!query.facilityName"
                  >
                    <i class="icon cil-search"></i> {{ $t("common.search") }}
                  </CButton>
                </CCol>
              </template>
              <!-- #endregion 宿名検索 -->
              <!-- #region 全国地方公共団体コード検索 -->

              <!-- 一時的にコメントアウトします-->
              <template v-if="isSelectedQuery('areaId')">
                <CCol sm="3" lg="3">
                  <CInput
                    class="search-input"
                    :placeholder="$t('facilityListModal.areaidPlaceholderText')"
                    v-model="query.areaId"
                    type="text"
                    @keydown.enter="onSearchQueryEnter($event, 'areaId')"
                  />
                </CCol>
                <CCol sm="3" lg="3">
                  <CButton
                    @click="onSearchClicked('areaId')"
                    class="info-button"
                    :disabled="!query.areaId"
                  >
                    <i class="icon cil-search"></i> {{ $t("common.search") }}
                  </CButton>
                </CCol>
              </template>

              <!-- #endregion 全国地方公共団体コード検索 -->

              <!-- #region タグ名検索 -->
              <!-- 一時的にコメントアウトします-->
              <!-- <template v-if="isSelectedQuery('tagName')">
                <CCol sm="3" lg="3">
                  <CInput
                    class="search-input"
                    :placeholder="
                      $t('facilityListModal.tagNamePlaceholderText')
                    "
                    v-model="query.tagName"
                    type="text"
                    @keydown.enter="onSearchQueryEnter($event, 'tagName')"
                  />
                </CCol>
                <CCol sm="3" lg="3">
                  <CButton
                    @click="onSearchClicked('tagName')"
                    class="info-button"
                    :disabled="!query.tagName"
                  >
                    <i class="icon cil-search"></i> {{ $t("common.search") }}
                  </CButton>
                </CCol>
              </template> -->

              <!-- #endregion タグ名検索 -->

              <!-- #region 郵便番号検索 -->
              <template v-if="isSelectedQuery('zipcode')">
                <CCol sm="3" lg="3">
                  <CInput
                    class="search-input"
                    :placeholder="
                      $t('facilityListModal.zipcodePlaceholderText')
                    "
                    v-model="query.zipcode"
                    @keydown.enter="onEnterKeyPress($event, 'zipcode')"
                    type="text"
                  />
                </CCol>
                <CCol sm="3" lg="3">
                  <CButton
                    @click="onSearchClicked('zipcode')"
                    class="info-button"
                    :disabled="!query.zipcode"
                  >
                    <i class="icon cil-search"></i> {{ $t("common.search") }}
                  </CButton>
                </CCol>
              </template>
              <!-- #endregion 郵便番号検索 -->
              <!-- #region 電話番号検索 -->
              <template v-if="isSelectedQuery('tel')">
                <CCol sm="3" lg="3">
                  <CInput
                    class="search-input"
                    :placeholder="$t('facilityListModal.telPlaceholderText')"
                    v-model="query.tel"
                    @keydown.enter="onEnterKeyPress($event, 'tel')"
                    type="text"
                  />
                </CCol>
                <CCol sm="3" lg="3">
                  <CButton
                    @click="onSearchClicked('tel')"
                    class="info-button"
                    :disabled="!query.tel"
                  >
                    <i class="icon cil-search"></i> {{ $t("common.search") }}
                  </CButton>
                </CCol>
              </template>

              <!-- #endregion 電話番号検索 -->
            </CRow>
            <transition name="fade">
              <CRow v-if="notFound">
                <CCol lg="6">
                  <CAlert color="danger">{{
                    $t("facilityListModal.searchFailed")
                  }}</CAlert>
                </CCol>
              </CRow>
            </transition>
          </CForm>
        </CCol>
      </CRow>
      <!-- #endregion 検索 -->
      <!-- #region モーダルテーブル -->
      <h4 class="sub-title">
        {{ $t("facilityListModal.searchResult") }}
      </h4>
      <p>
        <span>{{ $t("facilityListModal.totalNumber") }}</span
        >: {{ facilityCount }} |
        <span>{{ $t("facilityListModal.resultNumber") }}</span
        >: {{ facilities.length }}
      </p>

      <CDataTable
        class="mb-0 table-outline"
        hover
        size="sm"
        :items="facilities"
        :fields="tableFields"
        head-color="light"
        table-filter
        column-filter
        sorter
        items-per-page-select
        :items-per-page="facilityPagenationSelected"
        pagination
        v-if="!loading"
        :tableFilter='{ placeholder: $t("tableFilter.placeholder"), label: $t("tableFilter.label") }'
      >
        <td slot="checkbox" slot-scope="{ item }">
          <CInputCheckbox
            class="mb-4 ml-2 bg-light"
            :checked="checkedEvent(item)"
            v-model="item.checkbox"
            :disabled="
              addeds.find((a) => a === item.uniqueId) ? 'disabled' : undefined
            "
          />
        </td>
        <td slot="uniqueId" class="text-left" slot-scope="{ item }">
          {{ item.uniqueId }}
        </td>
        <td slot="name" class="text-left" slot-scope="{ item }">
          {{ item.name }}
        </td>
        <td slot="pronunciation" class="text-left" slot-scope="{ item }">
          {{ item.pronunciation }}
        </td>
        <td slot="address" slot-scope="{ item }" class="text-left">
          {{ item.address1 + item.address2 }}
        </td>
        <td slot="telephone" slot-scope="{ item }" class="text-left">
          {{ item.telephone }}
        </td>
        <td slot="email" slot-scope="{ item }" class="text-left">
          {{ item.email }}
        </td>
      </CDataTable>
      <!-- #endregion モーダルテーブル -->
      <!-- #region モーダルフーター -->
      <template #footer>
        <CButton class="float-left" @click="onCancelClicked()" color="secondary"
          ><i class="icon cil-x"></i>
          {{ $t("common.cancel") }}
        </CButton>
        <CButton
          class="float-right"
          @click="onReflectClicked(facilities)"
          color="info"
          ><i class="icon cil-sync"></i>
          {{ $t("common.reflect") }}
        </CButton>
      </template>
      <!-- #endregion モーダルフーター -->
    </CModal>
  </div>
</template>

<script>
import i18n from "@/i18n";

export default {
  name: "AddFacilityModal",

  data() {
    return {
      addFacilityModal: false,
      collapse: false,
      loading: false,
      /** 検索済みか？ */
      searched: false,
      errors: "",
      facilityPagenationCounts: [
        { value: 5 },
        { value: 10 },
        { value: 20 },
        { value: 50 },
      ],
      facilityPagenationSelected: 5,

      //#endregion Flag

      //TODO:宿の検索条件
      // query: {
      //   areaId: "",
      //   tagName: "",
      //   zipcode: "",
      //   tel: "",
      //   facilityName: "",
      // },

      //#region Table

      /** 宿検索結果 */
      //facilities: [],
      facilityCount: 0,

      /** 一覧列名 */
      tableFields: [
        {
          key: "checkbox",
          label: i18n.t("common.add"),
          sorter: false,
          filter: false,
          _classes: "text-center mt-2 bg-light",
          _style: "width:6%",
        },
        {
          key: "uniqueId",
          label: "ID",
          _classes: "text-center bg-light",
          _style: "width:5%",
        },
        {
          key: "name",
          label: i18n.t("facilityListModal.facilitiesList.tableHeader.name"),
          _classes: "text-center bg-light",
        },
        {
          key: "pronunciation",
          label: i18n.t(
            "facilitySearchPage.facilitiesList.tableHeader.pronunciation"
          ),
          sorter: true,
          filter: true,
          _classes: "text-center bg-light",
        },
        {
          key: "address",
          label: i18n.t("facilityListModal.facilitiesList.tableHeader.address"),
          _classes: "text-center bg-light",
        },
        {
          key: "telephone",
          label: i18n.t(
            "facilityListModal.facilitiesList.tableHeader.telephone"
          ),
          _classes: "text-center bg-light",
        },
        {
          key: "email",
          label: i18n.t("facilityListModal.facilitiesList.tableHeader.email"),
          _classes: "text-center bg-light",
        },
      ],

      /** 一覧表示データ */
      // 開発用
      facilities: [],
      /** 検索クエリ */
      query: {
        areaId: "",
        tagName: "",
        zipcode: "",
        tel: "",
        facilityName: "",
      },
      //TODO:宿の検索条件
      /** 検索条件選択 */
      queryOptions: [
        {
          value: "facilityName",
          label: i18n.t("facilityListModal.facilityName"),
        },
        { value: "areaId", label: i18n.t("facilityListModal.areaid") },

        { value: "zipcode", label: i18n.t("facilityListModal.zipcode") },
        { value: "tel", label: i18n.t("facilityListModal.tel") },
        { value: "all", label: i18n.t("common.all") },

        // { value: "tagName", label: i18n.t("facilityListModal.tagName") },
      ],

      /** 選択中の検索条件 */
      selectedQuery: "facilityName",

      //#endregion Table

      //#region Response data

      //#endregion Response data
    };
  },
  props: {
    callback: {
      type: Function,
    },

    addeds: {
      type: Array,
    },
  },

  computed: {
    /** 有効リスト */
    enabledList() {
      return this.list.filter((a) => a.enabled);
    },

    /** 無効リスト */
    disabledList() {
      return this.list.filter((a) => !a.enabled);
    },

    isListLabel() {
      return this.listLabel != null && this.listLabel.length > 0;
    },
    /** 一覧表表示できるか？ */
    canShowTable() {
      return !this.loading && !this.notFound;
    },

    /** レスポンスデータが見つからないか？ */
    notFound() {
      return this.searched && this.users.length < 1;
    },

    /** 会員検索数 */
    userCount() {
      return this.users.length;
    },
  },

  methods: {
    //#region Event

    /** モーダル内の反映ボタン押下 */
    onReflectClicked(items) {
      this.callback(items);

      this.addFacilityModal = false;
    },

    onCancelClicked() {
      this.addFacilityModal = false;
    },
    /** 有効無効切替 */
    toggle(item) {
      item.enabled = !item.enabled;
    },

    onEnterKeyPress: function (event, value) {
      // 日本語入力中のEnterキー操作は無効
      if (event.keyCode !== 13) return;
      switch (value) {
        case "areaId":
          if (!this.query.areaId) return;
          break;
        case "tagName":
          if (!this.query.tagName) return;
          break;
        case "zipcode":
          if (!this.query.zipcode) return;
          break;
        case "tel":
          if (!this.query.tel) return;
          break;
        case "facilityName":
          if (!this.query.facilityName) return;
          break;
        case "all":
          if (!this.query.all) return;
          break;
        default:
          return;
      }
      this.onSearchClicked(value);
    },

    checkedEvent(item) {
      if (this.addeds.find((a) => a === item.uniqueId)) {
        return true;
      } else {
        return false;
      }
    },

    /** 検索ボタン押下 */
    onSearchClicked(value) {
      // 宿検索API URL
      let urlRequest;

      switch (value) {
        case "areaId":
          urlRequest = this.makeUrlRequest(value, this.query.areaId);
          break;
        case "tagName":
          urlRequest = this.makeUrlRequest(value, this.query.tagName);
          break;
        case "zipcode":
          urlRequest = this.makeUrlRequest(value, this.query.zipcode);
          break;
        case "tel":
          urlRequest = this.makeUrlRequest(value, this.query.tel);
          break;
        case "facilityName":
          urlRequest = this.makeUrlRequest(value, this.query.facilityName);
          break;
        case "all":
          urlRequest = `/rest/hotel/`;
          break;
      }

      if (!urlRequest) {
        // "検索条件を入力してください"
        alert(this.$i18n.t("facilitySearchPage.noQueryAlert"));
        return;
      }

      this.reqGetFacilities(urlRequest);
    },

    /**
     * リクエストURL作成
     * @param key 検索クエリキー
     * @param value 検索クエリ値
     */
    makeUrlRequest(key, value) {
      const isKeyAndValue = !key || !value;
      return isKeyAndValue ? "" : `/rest/hotel/?${key}=${value}`;
      // return isKeyAndValue ? "" : `http://localhost:8080/rest/hotel/?${key}=${value}`;
    },

    /** 検索ボタン押下・検索クエリ入力テキストEnterキー押下 */
    onSearchQueryEnter(event, value) {
      if (!this.validateQuery(event, value)) return;
      this.getSearchQuery(value);
      this.searched = true;
    },

    onAddFacilityClicked() {
      console.log(this.addeds);
      this.addFacilityModal = true;
    },

    //#endregion Event

    //#region Request
    /** 宿の登録件数取得 */
    reqGetFacilityTotalNumber() {
      
      const callback = (a) => {
        this.facilityCount = a.total;
        console.log(this.facilityCount);
      };

      this.reqGetHotelAll(callback);
    },


    /** 宿一覧取得リクエスト */
    reqGetFacilities(urlRequest) {
      this.facilities = [];
      this.facilityNotFound = false;

      const callback = (a) => {
        this.facilities = a;

         // 検索結果0件の時
         if (this.facilities.length === 0) {
           this.facilityNotFound = true;
         }
      };

      const errorCallback = (err) => {
        console.log(err);
        this.facilityNotFound = true;
      };

      this.reqGetSearchHotel(urlRequest,callback,errorCallback);
    },

    //#endregion Request

    //#region Method

    /**
     * 検索クエリを検証
     */
    validateQuery(event, value) {
      // 日本語入力中のEnterキー操作は無効
      if (event.keyCode === 229) return false;
      // if (event.keyCode !== 13) return false;

      if (!value) return false;
      if (!this.query[value]) return false;

      return true;
    },

    /** 選択中の検索クエリか？ */
    isSelectedQuery(query) {
      return this.selectedQuery === query;
    },

    /**
     * 一覧表をクリア
     */
    clearTable() {
      this.facilities = [];
    },

    //#endregion Method
  },
  beforeMount() {
    //宿の登録件数取得
    this.reqGetFacilityTotalNumber();
    this.clearTable();
  },
};
</script>

<style lang="scss" scoped>
.exchange-mark {
  position: relative;
  i {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.search-filter .search-btn {
  margin-bottom: 1em;

  .search-lbl {
    display: inline-block;
    margin-right: 15px;
    select {
      min-width: 200px;
    }
  }
  .search-filter {
    .row {
      .search-input {
        margin: 0;
      }

      .text-danger {
        padding-left: 15px;
        font-size: 0.9em;
      }
    }

    button {
      max-width: 100px;
    }
  }
  .editor-title {
    font-size: 1.1em;
    border-left: 2px solid #194583;
    padding: 0 0 0 5px;
    margin-bottom: 10px;
  }

  // display: inline-block;
}
</style>